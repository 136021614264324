import { FlagProvider as UnleashProxyFlagProvider, useUnleashClient } from '@unleash/proxy-client-react';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import type { IConfig } from 'unleash-proxy-client';

import { useAppSelector } from '../store/hooks';
import { selectClientId } from '../store/user/selectors';

const config: IConfig = {
  appName: 'client-app',
  url: process.env.REACT_APP_FLAGS_CTX_URL!,
  clientKey: process.env.REACT_APP_FLAGS_CTX_CLIENT_KEY!,
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  refreshInterval: 300, // 5 minutes
};

function SyncContext({ children }: PropsWithChildren) {
  const clientId = useAppSelector(selectClientId) ?? undefined;

  const unleashClient = useUnleashClient();

  useEffect(() => {
    const { userId } = unleashClient.getContext();

    if (userId !== clientId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- ignore promise return
      unleashClient.updateContext({ userId: clientId });
    }
  }, [unleashClient, clientId]);

  return children;
}

type FlagProviderProps = Omit<ComponentPropsWithoutRef<typeof UnleashProxyFlagProvider>, 'config'> & {
  /** If true, the provider will not try to access the userId from redux to update the unleash context */
  isAnonymous?: boolean;
};

export function FlagProvider(props: FlagProviderProps) {
  if (props.isAnonymous) {
    return <UnleashProxyFlagProvider {...props} config={config} />;
  }

  return (
    <UnleashProxyFlagProvider {...props} config={config}>
      <SyncContext>{props.children}</SyncContext>
    </UnleashProxyFlagProvider>
  );
}
